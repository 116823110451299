

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}


/* 
@media (max-width: 480px) {
  body{
    background: url('../public/images/bg-mob.jpg');
  }
}  */


/* html { 
  background:  url('../public/images/bg-web.jpg') no-repeat center center fixed; 
  -webkit-background-size: cover;
  -moz-background-size: cover;
  -o-background-size: cover;
  background-size: cover;
} */