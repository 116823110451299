.center_botton{
    padding-top: 20px;
    padding-bottom: 20px;
    align-content: center;
    
    text-align: center;
    
}
/* .botton1{
    transition-property: transform, box-shadow;
    transition-duration: .6s;
} */
.botton1:hover {
    transition-property: transform, box-shadow;
    transition-duration: .6s;
    transform: scale(1.25);
    box-shadow: 0 12px 16px 0 rgba(0,0,0,0.24), 0 17px 50px 0 rgba(0,0,0,0.19);
  }

