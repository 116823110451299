.footer {
  background-color: #333;
  color: #fff;
  font-size: 1rem;
  padding: 1rem 0;
  text-align: center;
}

.footer-social-media a {
  color: #fff;
  margin: 0 0.5rem;
  font-size: 1.5rem;
  align-items: center;
}


.footer-copyright {
  margin-top: 1rem;
  text-align: center;
}

@media (max-width: 480px) {
  .footer-social-media {
    margin-top: 1rem;
    text-align: center;
  }
}
