.center{
    text-align: center;
}

.center .enlarge {
    margin-top: 20px;
    width: 10%;
    height: 10%;
    border-radius: 100%;
    margin-bottom: 20px;
    
    -webkit-transition: all 0.5s ease-in-out;
    -moz-transition: all 0.5s ease-in-out;
    transition: all 0.5s ease-in-out;
}
.enlarge:hover {
    width: 15%;
    height: 15%;
       -webkit-transition: all 0.5s ease-in-out;
    -moz-transition: all 0.5s ease-in-out;
    transition: all 0.5s ease-in-out;
  cursor: pointer;
}

@media (max-width: 560px){
    .center .enlarge{
        width: 30%;
    }
}